import './external-user-profile-sections.styles.scss';

import React, { FC } from 'react';
import { formatDateToSlash } from '../../../utils/externalUtilsFunction';

import verified from '../../../assets/icons/verified-green-tick.svg';
import verifiedBlue from '../../../assets/icons/certificate/circle-tick.svg';
import pending from '../../../assets/icons/pending.svg';

type Props = {
	sectionStatus: string;
	licenceCheckDate?: string;
};

const ExternalUserProfileStatus: FC<Props> = ({
	sectionStatus,
	licenceCheckDate,
}) => {
	if (sectionStatus === 'verified') {
		return (
			<div className='status'>
				<img
					className='status-icon'
					src={verifiedBlue}
					alt='verified'
				/>
				<span className='status-text status-text-complete'>
					{licenceCheckDate
						? `Licence(s) checked on ${formatDateToSlash(
							licenceCheckDate,
						)}`
						: 'Complete'}
				</span>
			</div>
		);
	} else if (sectionStatus === 'partially_verified') {
		return (
			<div className='status'>
				<img
					className='status-icon'
					src={verified}
					alt='partially verified'
				/>
				<span className='status-text status-text-partially-verified'>
					Partially Verified
				</span>
			</div>
		);
	} else {
		return (
			<div className='status'>
				<img className='status-icon' src={pending} alt='pending' />
				<span className='status-text status-text-pending-review'>
					Pending Review
				</span>
			</div>
		);
	}
};

export default ExternalUserProfileStatus;
