import './external-user-profile-sections.styles.scss';

import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import {
	CriminalBankruptcyItem,
	NameHistoryItem,
	User,
} from '../../../types/interface';
import { formatDateToText } from '../../../utils/externalUtilsFunction';

import * as utils from '../../../utils/utilsFunctions';
import ExternalUserProfileStatus from './external-user-profile-status.component';
import ExternalUserProfileRisks from '../external-user-profile-risks/external-user-profile-risks.component';

import flagDarkGrey from '../../../assets/icons/flag-dark-grey.svg';
import flagRed from '../../../assets/icons/flag-red.svg';
import menuIcon from '../../../assets/icons/external-portal/user-profile/menu.svg';
import checklistIcon from '../../../assets/icons/external-portal/user-profile/checklist.svg';
import companyIcon from '../../../assets/icons/external-portal/user-profile/company.svg';
import viewIcon from '../../../assets/icons/external-portal/user-profile/view.svg';

type Props = {
	loggedInUser: User;
};

type DisclosureSectionProps = {
	data?: CriminalBankruptcyItem[];
	title: string;
	renderItem: (items: CriminalBankruptcyItem[]) => ReactNode;
};

const ExternalUserProfileChecks: FC<Props> = ({ loggedInUser }) => {
	const criminalBankruptcy = useSelector(
		(state: RootState) => state.profile.criminalBankruptcy,
	);

	const nameHistory = useSelector(
		(state: RootState) => state.profile.nameHistory,
	);

	const stageData = useSelector(
		(state: RootState) => state.profile.stageData,
	);

	const [conviction, setConviction] = useState<CriminalBankruptcyItem[]>();
	const [awaitingTrial, setAwaitingTrial] =
		useState<CriminalBankruptcyItem[]>();
	const [caution, setCaution] = useState<CriminalBankruptcyItem[]>();
	const [warCrimes, setWarCrimes] = useState<CriminalBankruptcyItem[]>();
	const [terrorism, setTerrorism] = useState<CriminalBankruptcyItem[]>();
	const [terroristView, setTerroristView] =
		useState<CriminalBankruptcyItem[]>();
	const [badCharacter, setBadCharacter] =
		useState<CriminalBankruptcyItem[]>();

	useEffect(() => {
		setConviction(
			criminalBankruptcy.filter((el) => el.type === 'conviction'),
		);
		setAwaitingTrial(
			criminalBankruptcy.filter((el) => el.type === 'awaiting trial'),
		);
		setCaution(criminalBankruptcy.filter((el) => el.type === 'bankruptcy'));
		setWarCrimes(
			criminalBankruptcy.filter((el) => el.type === 'war crimes'),
		);
		setTerrorism(
			criminalBankruptcy.filter((el) => el.type === 'terrorism'),
		);
		setTerroristView(
			criminalBankruptcy.filter((el) => el.type === 'terrorist views'),
		);
		setBadCharacter(
			criminalBankruptcy.filter((el) => el.type === 'bad character'),
		);
	}, [criminalBankruptcy]);

	const getSectionStatus = () => {
		const isAllVerified = [4, 5, 6].every(
			(i) => stageData[i]?.stageComplete,
		);
		const isPartiallyVerified = [4, 5, 6].some(
			(i) => stageData[i]?.stageComplete,
		);

		if (isAllVerified) {
			return 'verified';
		} else if (isPartiallyVerified) {
			return 'partially_verified';
		} else {
			return '';
		}
	};

	const legalNames = nameHistory.filter((name) => name.nameType === 'legal');
	const aliasNames = nameHistory.filter((name) => name.nameType === 'alias');

	const renderNames = (names: NameHistoryItem[]) => {
		if (!names.length) {
			return (
				<div className='external-user-profile-sub-section-name-history-body'>
					None Submitted
				</div>
			);
		}

		return names.map((name, index) => (
			<div
				key={index}
				className='external-user-profile-sub-section-name-history-body'
			>
				{name.forename} {name.middleNames ? `${name.middleNames} ` : ''}
				{name.surname}
			</div>
		));
	};

	const renderNameHistory = () => {
		return (
			<section className='external-user-profile-sub-section-container'>
				<div className='external-user-profile-sub-section-title-container remove-pointer'>
					<img
						className='external-user-profile-sub-section-icon external-user-profile-sub-section-shrink-icon'
						src={menuIcon}
						alt='menu-icon'
					/>
					<p className='external-user-profile-sub-section-external-check-title'>
						Other Names
					</p>
				</div>
				<div className='external-user-profile-sub-section-name-history-container'>
					<div className='external-user-profile-sub-section-subtitle-name-history-container'>
						<p className='external-user-profile-sub-section-name-history-subtitle'>
							Legal Names
						</p>
						{renderNames(legalNames)}
					</div>
					<div className='external-user-profile-sub-section-subtitle-name-history-container'>
						<p className='external-user-profile-sub-section-name-history-subtitle'>
							Alias Names
						</p>
						{renderNames(aliasNames)}
					</div>
				</div>
				<div className='external-user-profile-sub-section-divider-line'></div>
			</section>
		);
	};

	const DisclosureSection = ({
		data = [],
		title,
		renderItem,
	}: DisclosureSectionProps) => {
		const disclosureData = data && data.length > 0;

		return (
			<>
				<div className='external-user-profile-sub-section-subtitle-container'>
					<p className='external-user-profile-sub-section-subtitle'>
						{title}
					</p>
				</div>
				{disclosureData ? (
					<div className='external-user-profile-sub-section-body'>
						<div>{renderItem(data)}</div>
					</div>
				) : (
					<div className='external-user-profile-sub-section-body'>
						<p className='external-user-profile-sub-section-text-light'>
							None Submitted
						</p>
					</div>
				)}
			</>
		);
	};

	const renderConviction = (conviction: CriminalBankruptcyItem[]) => {
		return <div>{renderDisclosuresItem('Conviction', conviction)}</div>;
	};

	const renderAwaitingTrial = (awaitingTrial: CriminalBankruptcyItem[]) => {
		return <div>{renderDisclosuresItem('Offence', awaitingTrial)}</div>;
	};

	const renderCaution = (caution: CriminalBankruptcyItem[]) => {
		return <div>{renderDisclosuresItem('Caution', caution)}</div>;
	};

	const renderWarCrimes = (caution: CriminalBankruptcyItem[]) => {
		return <div>{renderDisclosuresItem('War Crime', caution)}</div>;
	};

	const renderTerrorism = (caution: CriminalBankruptcyItem[]) => {
		return (
			<div>{renderDisclosuresItem('Terrorism Activity', caution)}</div>
		);
	};

	const renderTerroristView = (caution: CriminalBankruptcyItem[]) => {
		return <div>{renderDisclosuresItem('Views', caution)}</div>;
	};

	const renderBadCharacter = (caution: CriminalBankruptcyItem[]) => {
		return <div>{renderDisclosuresItem('Activity', caution)}</div>;
	};

	const renderDisclosuresItem = (
		label: string,
		items: CriminalBankruptcyItem[],
	) => {
		return items.map((el, index) => {
			return (
				<div
					className='external-user-profile-sub-section-disclosure-container'
					key={el.id}
				>
					<p className='external-user-profile-sub-section-disclosure'>
						{label} {index + 1}
					</p>
					{el.sentence && (
						<div>
							<span className='external-user-profile-sub-section-disclosure-title'>
								Sentence / Disposal:
								<p className='external-user-profile-sub-section-disclosure-text'>
									{el.sentence}
								</p>
							</span>
						</div>
					)}
					<div>
						<span className='external-user-profile-sub-section-disclosure-title'>
							Details:
							<p className='external-user-profile-sub-section-disclosure-text'>
								{el.details}
							</p>
						</span>
					</div>
					<div>
						<span className='external-user-profile-sub-section-disclosure-title'>
							Date of Offence:
							<p className='external-user-profile-sub-section-disclosure-text'>
								{utils.formatDateForUser(el.date)}
							</p>
						</span>
					</div>
				</div>
			);
		});
	};

	const renderDisclosuresSection = () => {
		return (
			<>
				<DisclosureSection
					data={conviction}
					title='Conviction, caution or written warnings within the last 12 months'
					renderItem={renderConviction}
				/>
				<DisclosureSection
					data={awaitingTrial}
					title='Offences that are currently awaiting trial'
					renderItem={renderAwaitingTrial}
				/>
				<DisclosureSection
					data={caution}
					title='Bankruptcy proceedings and court judgments'
					renderItem={renderCaution}
				/>
				<DisclosureSection
					data={warCrimes}
					title='Involvement in war crimes'
					renderItem={renderWarCrimes}
				/>
				<DisclosureSection
					data={terrorism}
					title='Involvement or support of terrorist activities'
					renderItem={renderTerrorism}
				/>
				<DisclosureSection
					data={terroristView}
					title='Expressed views that glorify terrorist acts'
					renderItem={renderTerroristView}
				/>
				<DisclosureSection
					data={badCharacter}
					title='Could you be considered of bad character'
					renderItem={renderBadCharacter}
				/>
				<div className='external-user-profile-sub-section-subtitle-container'>
					<p className='external-user-profile-sub-section-subtitle'>
						Notes
					</p>
				</div>
				{stageData[6]?.notes ? (
					<div className='external-user-profile-sub-section-body'>
						<div className='external-user-profile-sub-section-disclosure-note'>
							{stageData[6]?.notes}
						</div>
					</div>
				) : (
					<div className='external-user-profile-sub-section-body'>
						<p className='external-user-profile-sub-section-text-light'>
							None Submitted
						</p>
					</div>
				)}
			</>
		);
	};

	const renderDisclosures = () => {
		return (
			<section className='external-user-profile-sub-section-container'>
				<div className='external-user-profile-sub-section-title-container remove-pointer'>
					<img
						className='external-user-profile-sub-section-icon external-user-profile-sub-section-shrink-icon'
						src={checklistIcon}
						alt='checklist-icon'
					/>
					<p className='external-user-profile-sub-section-external-check-title'>
						Disclosures
					</p>
					<p
						className='external-user-profile-sub-section-status'
						style={{
							color: stageData[6]?.stageComplete
								? '#485BEB'
								: '#F8C96A',
						}}
					>
						{stageData[6]?.stageComplete ? 'Cleared' : 'Pending'}
					</p>
				</div>
				{renderDisclosuresSection()}
				{stageData[6]?.stageComplete ? (
					<div className='last-verified last-verified-margin'>
						Last Verified on{' '}
						{formatDateToText(stageData[6]?.stageCompletedAt)}
					</div>
				) : null}
				{stageData[6]?.risks.length > 0 && (
					<>
						<div className='user-profile-section-divider-line'></div>
						<div className='external-user-profile-sub-section-risk-margin'>
							<ExternalUserProfileRisks
								stageData={stageData[6]}
								loggedInUser={loggedInUser}
							/>
						</div>
					</>
				)}
				{stageData[6]?.risks.length === 0 && (
					<div className='external-user-profile-sub-section-divider-line'></div>
				)}
			</section>
		);
	};

	const renderExternalCheckSection = (
		iconSrc: string,
		title: string,
		stageDataIndex: number,
		subtitleText: string,
	) => {
		return (
			<section className='external-user-profile-sub-section-container'>
				<div className='external-user-profile-sub-section-title-container remove-pointer'>
					<img
						className='external-user-profile-sub-section-icon external-user-profile-sub-section-shrink-icon'
						src={iconSrc}
						alt='external-check-icon'
					/>
					<p className='external-user-profile-sub-section-external-check-title'>
						{title}
					</p>
					<p
						className='external-user-profile-sub-section-status'
						style={{
							color: stageData[stageDataIndex]?.stageComplete
								? '#485BEB'
								: '#F8C96A',
						}}
					>
						{stageData[stageDataIndex]?.stageComplete
							? 'Cleared'
							: 'Pending'}
					</p>
				</div>
				<div className='external-user-profile-sub-section-subtitle-container'>
					<p className='external-user-profile-sub-section-subtitle'>
						{stageData[stageDataIndex]?.notes
							? stageData[stageDataIndex]?.notes
							: subtitleText}
					</p>
				</div>
				{stageData[stageDataIndex]?.stageComplete ? (
					<div className='last-verified last-verified-margin'>
						Last Verified on{' '}
						{formatDateToText(
							stageData[stageDataIndex]?.stageCompletedAt,
						)}
					</div>
				) : null}
				{stageData[stageDataIndex]?.risks.length > 0 && (
					<>
						<div className='user-profile-section-divider-line'></div>
						<div className='external-user-profile-sub-section-risk-margin'>
							<ExternalUserProfileRisks
								stageData={stageData[stageDataIndex]}
								loggedInUser={loggedInUser}
							/>
						</div>
					</>
				)}
				{stageData[stageDataIndex]?.risks.length === 0 && (
					<div className='external-user-profile-sub-section-divider-line'></div>
				)}
			</section>
		);
	};

	const renderExternalCheckSubSections = () => {
		const nameHistorySection = renderNameHistory();
		const disclosureSection = renderDisclosures();
		const companyHouseSection = renderExternalCheckSection(
			companyIcon,
			'Companies House Check',
			4,
			'No Matches',
		);
		const globalWatchlistSection = renderExternalCheckSection(
			viewIcon,
			'Public Record / Global Watchlist Check',
			5,
			'No Matches',
		);

		return (
			<>
				{nameHistorySection}
				{disclosureSection}
				{companyHouseSection}
				{globalWatchlistSection}
			</>
		);
	};

	return (
		<section className='view-container'>
			<div className='identity-container'>
				<p className='identity-header-title'>External Checks</p>
				<div className='identity-header-right'>
					<ExternalUserProfileStatus sectionStatus={getSectionStatus()} />
					<img
						className='risk-flag'
						src={
							(stageData[4] && stageData[4].risks.length > 0) ||
								(stageData[5] && stageData[5].risks.length > 0) ||
								(stageData[6] && stageData[6].risks.length > 0)
								? flagRed
								: flagDarkGrey
						}
						alt='flag'
					/>
				</div>
			</div>
			<div className='user-profile-section-divider-line'></div>
			<div className='user-profile-section-main'>
				{renderExternalCheckSubSections()}
			</div>
		</section>
	);
};

export default ExternalUserProfileChecks;
