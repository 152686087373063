import React, { FC } from 'react';
import './yoti-badge.styles.scss';
import YotiLogo from '../../../assets/icons/external-portal/user-profile/yoti-logo.svg';
import InfoIcon from '../../../assets/icons/grey-info-dark.svg';
import { IYotiData } from '../../../types/interfaces/rtw.interface';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';

type Props = {
	yotiDetails: IYotiData;
};

const TooltipLg = withStyles({
	tooltip: {
		backgroundColor: '#FFFFFF !important',
	},
})(Tooltip);

const getYotiStatus = (yotiStatus: string) => {
	switch (yotiStatus) {
		case 'in-progress':
		case 'processing':
			return {
				status: 'PROCESSING',
				explainerText: 'Yoti is processing this document. This usually takes a few minutes, please check back soon.',
			}
		case 'approved':
			return {
				status: 'PASS',
				explainerText: 'Yoti has assessed this document as valid and authentic.',
			}
		case 'rejected':
		case 'abandoned':
		case 'expired':
			return {
				status: 'REJECT',
				explainerText: 'Yoti has not been able to gather enough data to confirm this document. Occasionally this happens as a result of glare or blur on the document. If you believe the document to be real and valid, you are still able to confirm the check.',
			}
		default:
			return {
				status: null,
			}
	}
}

const YotiBadge: FC<Props> = ({ yotiDetails }) => {
	const badgeInfo = getYotiStatus(yotiDetails.sessionStatus);
	return (
		<>
			{badgeInfo.status &&
				<TooltipLg title=
					{
						<div className='tooltip'>
							<p style={{ fontSize: '14px' }}>{badgeInfo.explainerText}</p>
						</div>
					}>
					<div className={`${yotiDetails.sessionStatus}-badge yoti-badge`}>
						<img className='yoti-logo' src={YotiLogo} />
						<p className='yoti-badge-text'><span>RECOMMENDED: </span>{badgeInfo.status}</p>
						<img className='yoti-badge-info' src={InfoIcon} />
					</div>
				</TooltipLg>
			}
		</>
	)
}

export default YotiBadge;