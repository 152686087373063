import './external-user-profile-sections.styles.scss';

import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { formatDateToText } from '../../../utils/externalUtilsFunction';
import {
	IFileItem,
	YotiDocs,
} from '../../../types/interfaces/document.interface';
import { YotiDocumentDetails } from '../../../types/interface';

import * as utils from '../../../utils/utilsFunctions';
import ExternalUserProfileStatus from './external-user-profile-status.component';
import LivenessCheck from '../../profile/liveness-check/liveness-check.component';
import RtwImageDoc from '../../profile/rtw-image-doc/rtw-image-doc';
import ProfileDocumentDetails from '../../profile/profile-document-details/profile-document-details.component';

import cheveronDown from '../../../assets/icons/chevron-down.svg';
import greenTick from '../../../assets/icons/green-tick.svg';
import downloadFileIcon from '../../../assets/icons/external-portal/user-profile/download-file.svg';
import YotiBadge from '../../yoti/yoti-badge/yoti-badge.comonent';
import GuidanceToggle from '../../general/guidance-toggle/guidance-toggle.component';

type Props = {
	rtwManualDocArray: IFileItem[];
	yotiFaceToFaceArray: YotiDocs[];
	yotiRtwArray: YotiDocs[];
	faceToFaceArray: IFileItem[];
	yotiDocumentDetails?: YotiDocumentDetails;
};

const ExternalUserProfileIdentity: FC<Props> = ({
	rtwManualDocArray,
	yotiFaceToFaceArray,
	yotiRtwArray,
	faceToFaceArray,
	yotiDocumentDetails,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const collapsibleSectionStyle = { display: isOpen ? 'block' : 'none' };

	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const yotiDetails = useSelector(
		(state: RootState) => state.profile.yotiDetails,
	);

	const stageData = useSelector(
		(state: RootState) => state.profile.stageData,
	);

	const { ambassadorCheckStatus } = useSelector(
		(state: RootState) => state.profile,
	);

	const visibleFiles = faceToFaceArray.filter(
		(file: IFileItem) => !file.deletedAt && file.status !== 'rejected',
	);
	const formatConfidenceLevel = (levelOfConfidence?: string) => {
		if (!levelOfConfidence) {
			return 'Medium';
		}

		if (levelOfConfidence === 'very_high') {
			return 'Very high';
		}

		return levelOfConfidence.charAt(0).toUpperCase() + levelOfConfidence.slice(1);
	}
	const mostRecentImg = () => {
		return rtwManualDocArray.slice(-1)[0];
	};

	const renderFaceToFaceFileSection = () => {
		return (
			<>
				{faceToFaceArray && faceToFaceArray.length > 0 ? (
					<div className='external-user-profile-sub-section-body'>
						<div className='external-user-profile-sub-section-file-container'>
							<p className='external-user-profile-sub-section-file-title'>
								Files
							</p>
							{visibleFiles.map(
								(file: IFileItem, index: number) => (
									<div
										key={index}
										className='external-user-profile-sub-section-file-content'
									>
										<a
											href={file.readUrl}
											target='_blank'
											rel='noreferrer'
											className='external-user-profile-sub-section-file-link'
										>
											<img
												className='external-user-profile-sub-section-file-icon'
												src={downloadFileIcon}
												alt='file-icon'
											/>
											<span className='external-user-profile-sub-section-file-number'>
												File{' '}
												{visibleFiles.indexOf(file) + 1}
											</span>
										</a>
									</div>
								),
							)}
						</div>
					</div>
				) : (
					<div className='external-user-profile-sub-section-file-container'>
						<p className='external-user-profile-sub-section-file-title'>
							Files
						</p>
						<p className='external-user-profile-sub-section-file-text'>
							No Files Available
						</p>
					</div>
				)}
			</>
		);
	};

	const renderRtwSection = () => {
		return (
			<section className='sub-section-container'>
				<div className='sub-section-title-container remove-pointer'>
					<p className='sub-section-title'>
						RIGHT TO WORK
					</p>
					<div>
						{yotiDetails && yotiDetails.sessionStatus &&
							<YotiBadge yotiDetails={yotiDetails} />
						}
					</div>
				</div>
				<div className='sub-section-identity-container'>
					{yotiRtwArray && yotiRtwArray.length > 0 &&
						<div className='sub-section-identity-document'>
							<RtwImageDoc
								imgSrc={yotiRtwArray[0].readUrl}
							/>
						</div>
					}
					{rtwManualDocArray && rtwManualDocArray.length > 0 &&
						<div className='sub-section-identity-document'>
							<RtwImageDoc imgSrc={mostRecentImg().readUrl} />
						</div>

					}
					<div className='sub-section-identity-confidence-level'>
						<img className='sub-section-identity-confidence-level-icon' src={greenTick} alt='Check' />
						<p>{formatConfidenceLevel(yotiDocumentDetails?.levelOfConfidence)} level of confidence</p>
					</div>
					<GuidanceToggle guidanceType='yoti-rtw' />
				</div>
			</section>
		);
	};
	const renderRTWPassportSection = () => {
		return (
			<>
				{yotiDocumentDetails &&
					<ProfileDocumentDetails
						yotiDocumentDetails={yotiDocumentDetails}
						isExternal={true}
					/>
				}
			</>
		);
	};

	const renderLivenessSection = () => {
		return (
			<section className='sub-section-container'>
				<div className='sub-section-title-container remove-pointer'>
					<p className='sub-section-title'>
						LIVENESS CHECK
					</p>
				</div>
				<div className='sub-section-identity-container'>
					{livenessCheck()}
					<GuidanceToggle guidanceType='liveness-check' />
				</div>
			</section>
		);
	};

	const livenessCheck = () => {
		if (!yotiDetails || yotiFaceToFaceArray.length === 0) {
			return;
		}
		return (
			<LivenessCheck
				fileArray={yotiFaceToFaceArray}
				showContainerClass={false}
			/>
		);
	};

	const renderIdentitySection = () => {
		return (
			<section className='sub-section-container'>
				<div className='sub-section-title-container remove-pointer'>
					<p className='sub-section-title'>
						IDENTITY CHECK
					</p>
				</div>
				<div className='sub-section-identity-container'>
					<div className='external-user-profile-sub-section-identity-subtitle-container'>
						<p className='external-user-profile-sub-section-identity-subtitle'>
							Ambassador Name:
						</p>
						<div className='external-user-profile-sub-section-identity-body'>
							{applicant.f2fCompletedBy
								? applicant.f2fCompletedBy
								: 'N/A'}
						</div>
					</div>
					<div className='external-user-profile-sub-section-identity-subtitle-container'>
						<p className='external-user-profile-sub-section-identity-subtitle'>
							Date Checked:
						</p>
						<div className='external-user-profile-sub-section-identity-body'>
							{applicant.f2fCompletedDate
								? utils.formatDateForUser(
									applicant.f2fCompletedDate,
								)
								: 'N/A'}
						</div>
					</div>
					{renderFaceToFaceFileSection()}
					<GuidanceToggle guidanceType='' />
				</div>
			</section>
		);
	};

	const renderNISection = () => {
		return (
			<section className='sub-section-container'>
				<div className='sub-section-title-container remove-pointer'>
					<p className='sub-section-title'>
						NATIONAL INSURANCE NUMBER
					</p>
				</div>
				<div className='sub-section-identity-container'>
					<p className='sub-section-subtitle'>
						{applicant.nationalInsuranceNumber.toUpperCase()}
					</p>
					<GuidanceToggle guidanceType='ni-number' />
				</div>
			</section>
		);
	};

	const getSectionStatus = () => {
		if (stageData[0]?.stageComplete && stageData[7]?.stageComplete) {
			return 'verified';
		} else if (stageData[0]?.stageComplete || stageData[7]?.stageComplete) {
			return 'partially_verified';
		} else {
			return '';
		}
	};

	return (
		<section className='view-container'>
			<div className='identity-container' onClick={() => setIsOpen(!isOpen)}>
				<p className='identity-header-title'>Right to Work and ID Verification</p>
				<div className='identity-header-right'>
					<ExternalUserProfileStatus sectionStatus={getSectionStatus()} />
					<img
						className='chevron'
						src={cheveronDown}
						alt='arrow'
						style={{ transform: isOpen ? 'scaleY(-1)' : 'none' }}
					></img>
				</div>
			</div>
			<div style={collapsibleSectionStyle}>
				{
					((yotiRtwArray && yotiRtwArray.length > 0) ||
						(rtwManualDocArray && rtwManualDocArray.length > 0) ||
						yotiDocumentDetails
					) ? (
						<>
							{((yotiRtwArray && yotiRtwArray.length > 0) ||
								(rtwManualDocArray && rtwManualDocArray.length > 0)) &&
								renderRtwSection()
							}
							{yotiDocumentDetails && renderRTWPassportSection()}
						</>
					) : (
						<section className='sub-section-container'>
							<div className='sub-section-non-submission-container '>
								<p className=''>Your candidate has not submitted anything yet.</p>
							</div>
						</section>
					)
				}
				{!yotiDetails &&
					ambassadorCheckStatus &&
					ambassadorCheckStatus.status !== 404 &&
					ambassadorCheckStatus.status !== 'started' &&
					renderIdentitySection()}
				{applicant.nationalInsuranceNumber && renderNISection()}
				{livenessCheck() && renderLivenessSection()}
			</div>
			<div className='last-verified'>
				{stageData[0]?.stageComplete || stageData[7]?.stageComplete
					? 'Last Verified on ' +
					formatDateToText(
						stageData[0].stageCompletedAt ||
						stageData[7].stageCompletedAt,
					)
					: ''}
			</div>
		</section>
	);
};

export default ExternalUserProfileIdentity;