import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';
import moment from 'moment';

export const getStatusBackgroundColour = (status: string): string => {
	switch (status) {
		case 'awaitingSubmission':
			return '#9AA5B140';
		case 'inProgress':
			return '#485BEB40';
		case 'limited':
			return '#FFEBB8';
		case 'full':
			return '#D5EFEC';
		case 'cancelled':
			return '#EC807940';
		case 'rtw':
			return '#E5C1E8';
		case 'bs7858':
			return '#B9BAF9';
		case 'rtwbs7858':
			return '#C1E8C7';
		default:
			return '';
	}
};

export const getStatusClassName = (status: string): string => {
	switch (status) {
		case 'awaitingSubmission':
			return 'status-awaiting';
		case 'inProgress':
			return 'status-in-progress';
		case 'limited':
			return 'status-limited';
		case 'full':
			return 'status-full';
		case 'cancelled':
			return 'status-cancelled';
		default:
			return '';
	}
};

export const getStatusExplainerText = (status: string) => {
	switch (status) {
		case 'rtw':
			return 'This candidate is performing a right to work check. You will need to confirm the candidates right to work.';
		case 'bs7858':
			return 'This candidate is performing a BS:7858 check only.';
		case 'rtwbs7858':
			return 'This candidate is performing a BS:7858 check and a right to work check. You will need to confirm the candidates right to work.';
		default:
			return '';
	}
}

export const getGuidanceExplainerText = (guidanceType: string) => {
	switch (guidanceType) {
		case 'yoti-rtw':
			return 'This is the candidate’s proof of right to work. You need to ensure the document is an authentic and valid British Passport, and be confident that it belongs to the candidate.';
		case 'yoti-rtw-details':
			return 'This data has been extracted from the document by Yoti, our verification partner. It is best practise to ensure this data matches the information that the candidate has provided you with during hiring.';
		case 'ni-number':
			return 'This is the other part of the candidate’s proof of right to work. You need to ensure the document is an official proof of name and national insurance number, and matches your records.'
		case 'liveness-check':
			return 'This liveness check has been performed so you can ensure the person in the right to work document provided matches the person performing the right to work check.';
		default:
			return '';
	}
}

export const mapStatusTab = (status: string): string => {
	switch (status) {
		case 'awaitingSubmission':
			return 'Awaiting Submission';
		case 'inProgress':
			return 'In Progress';
		case 'limited':
			return 'Limited Screening';
		case 'full':
			return 'Fully Compliant';
		case 'cancelled':
			return 'Cancelled';
		case 'rtw':
			return 'RTW';
		case 'bs7858':
			return 'BS:7858';
		case 'rtwbs7858':
			return 'BS:7858 and RTW'
		default:
			return '';
	}
};

//Checks if a user has been invited in the last two days and returns a boolean
export const checkInvitedDate = (date: string): boolean => {
	const currentDate = new Date();
	const parsedDate = new Date(date);
	const timeDifference = currentDate.getTime() - parsedDate.getTime();
	const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000;

	return timeDifference <= twoDaysInMilliseconds;
};

export const convertTimestampToDate = (timestamp: string): string => {
	const date = new Date(timestamp);
	const year = date.getFullYear();
	const month = `0${date.getMonth() + 1}`.slice(-2);
	const day = `0${date.getDate()}`.slice(-2);

	return `${year}-${month}-${day}`;
};

//Calculates how many days ago a user has been invited or how long a user has left (limited clearance)
export const checkApplicantDates = (
	date: string,
	startAt84Days: boolean,
): number => {
	const currentDate = new Date();
	const parsedDate = date.split('-');
	const year = parseInt(parsedDate[0], 10);
	const month = parseInt(parsedDate[1], 10) - 1;
	const day = parseInt(parsedDate[2], 10);

	const inputDate = new Date(year, month, day);

	const timeDifference = Math.abs(
		inputDate.getTime() - currentDate.getTime(),
	);

	let daysLeft = 0;

	if (startAt84Days) {
		daysLeft = 84 - Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
	} else {
		daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
	}
	return daysLeft;
};

export const DashboardToolTip = withStyles({
	arrow: {
		color: '#4A4A4A',
	},
	tooltip: {
		fontFamily: 'Avenir Next',
		backgroundColor: '#4A4A4A',
		color: '#FFFFFF',
		width: 160,
		borderRadius: 5,
		height: 30,
	},
})(Tooltip);

export const EmailRecipientToolTip = withStyles({
	arrow: {
		top: '15px !important',
		'&::before': {
			backgroundColor: '#4A4A4A',
		},
	},
	tooltip: {
		fontFamily: 'Avenir Next',
		backgroundColor: '#4A4A4A',
		color: '#FFFFFF',
		width: 140,
		borderRadius: 5,
		height: 42,
	},
})(Tooltip);

export const EmailRecipientInfoToolTip = withStyles({
	tooltip: {
		fontFamily: 'Avenir Next',
		backgroundColor: '#4A4A4A',
		color: '#FFFFFF',
		// width: 315,
		borderRadius: 5,
		//height: 30,
		zIndex: 999,
	},
})(Tooltip);

export const UserProfileInfoToolTip = withStyles({
	tooltip: {
		fontFamily: 'Avenir Next',
		backgroundColor: '#4A4A4A',
		color: '#FFFFFF',
		borderRadius: 5,
		zIndex: 999,
		width: 185,
		marginLeft: -1,
	},
})(Tooltip);

export const formatDateToText = (date: string): string => {
	return moment(date, 'YYYY-MM-DD').format('Do MMMM YYYY');
};
export const dateToText = (date: string): string => {
	return moment(date, 'DD/MM/YYYY').format('DD MMMM YYYY');
};

export const formatDateToSlash = (date: string): string => {
	return moment(date).format('DD/MM/YYYY');
};

export const capitalizeAndFormatPostcode = (postcode: string): string => {
	const formattedPostcode = postcode.replace(/\s+/g, '').toUpperCase();
	const firstPart = formattedPostcode.slice(0, -3);
	const secondPart = formattedPostcode.slice(-3);
	const formattedPostcodeString = `${firstPart} ${secondPart}`;
	return formattedPostcodeString;
};
